<template>
  <v-dialog :value="show" persistent width="500">
    <v-container v-if="invitation" class="incoming-call-dialog white--text" fill-height fluid>
      <v-row align="center" justify="center">
        <v-col>
          <h1>{{ invitation.invitee.name }}</h1>

          <v-container class="d-flex justify-center">
            <v-avatar class="pulse" size="128">
              <img :src="invitation.invitee.avatar" />
            </v-avatar>
          </v-container>

          <h2 class="mb-2">{{ invitation.conference.subject }}</h2>

          <v-container class="d-flex justify-center">
            <v-tooltip color="#236496" left open-delay="250">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="incoming-call-actions-button mx-4"
                  fab
                  dark
                  v-bind="attrs"
                  v-on="on"
                  @click="decline"
                >
                  <v-icon>$call_end</v-icon>
                </v-btn>
              </template>
              <span class="incoming-call-actions-tooltip">Ablehnen</span>
            </v-tooltip>

            <v-tooltip color="#236496" right open-delay="250">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="incoming-call-actions-button mx-4"
                  fab
                  dark
                  v-bind="attrs"
                  v-on="on"
                  @click="accept"
                >
                  <v-icon>$call</v-icon>
                </v-btn>
              </template>
              <span class="incoming-call-actions-tooltip">Annehmen</span>
            </v-tooltip>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </v-dialog>
</template>

<script>
const timeout = 30000; // ms

export default {
  name: "IncomingCallDialog",
  props: {
    invitation: {
      type: Object,
    },
  },
  data() {
    const audio = new Audio("sounds/incoming-call.mp3");
    audio.loop = true;
    return {
      audio,
      timeoutId: null,
    };
  },
  computed: {
    show() {
      return Boolean(this.invitation);
    },
  },
  watch: {
    show(value) {
      if (value) {
        this.audio.play();
        this.timeoutId = setTimeout(this.decline, timeout);
      }
    },
  },
  methods: {
    accept() {
      this.answer("accept");
    },
    answer(action) {
      this.clearTimeout();
      this.audio.pause();
      this.audio.currentTime = 0;
      this.$store.dispatch("invitations/" + action, this.invitation);
    },
    clearTimeout() {
      const { timeoutId } = this;
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    },
    decline() {
      this.answer("decline");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/variables.scss";

h1,
h2 {
  text-align: center;
  user-select: none;
}

.incoming-call-dialog {
  background: $color-background-lighten-1;
}

.v-avatar.pulse {
	animation: pulse 2s infinite;
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 rgba(130, 240, 155, .7);
	}
	
	70% {
		box-shadow: 0 0 0 10px rgba(130, 240, 155, 0);
	}
	
	100% {
		box-shadow: 0 0 0 0 rgba(130, 240, 155, 0);
	}
}

.incoming-call-actions-button {
  background: url(../assets/action-button.svg);
  background-color: transparent !important;
  box-shadow: none;

  &::before {
    content: none !important;
  }

  &:hover {
    background: url(../assets/action-button-hover.svg);

    .v-btn__content {
      .v-icon {
        padding: 1px;
      }
    }
  }
}

.incoming-call-actions-tooltip {
  color: #fff;
}
</style>
